<template>
    <div id="contents" class="login">
        <div class="page_body">
            <div class="login_form">
                <div class="header_area">
                    <h1>KT 차세대매장 디지털 컨텐츠 운영 시스템</h1>
                </div>
                <form name="" action="" method="">
                    <!-- s: 아이디 -->
                    <!--
                     is_error와 error_txt로 경고문구 배치
                     -->
                    <div :class="`input_area text h_1 ${!idValidation() ? 'is_error': ''}`">
                        <div class="input_box">
                            <input v-model="email" placeholder="이메일" @input="idValidation"/>
                        </div>
                        <small class="error_txt" v-if="!idValidation()">아이디(이메일)를 올바르게 입력 해 주세요.</small>
                    </div>
                    <!-- e: 아이디 -->
                    <!-- s: 비밀번호 -->
                    <div :class="`input_area text h_1 ${!pwValidation() ? 'is_error': ''}`">
                        <div class="input_box">
                            <input type="password" v-model="password" placeholder="비밀번호" @input="pwValidation"/>
                        </div>
                        <small class="error_txt" v-if="!pwValidation()">비밀번호를 입력 해 주세요.</small>
                    </div>
                    <!-- e: 비밀번호 -->
                    <!-- s: 체크박스 -->
                    <div class="input_area checkbox">
                        <div class="input_box">
                            <label>
                                <input type="checkbox" checked/>
                                <span class="ic_checkbox"></span>아이디 기억하기
                            </label>
                        </div>
                    </div>
                    <!-- e: 체크박스 -->
                    <!-- s: 경고문구 -->
                    <!-- Dev
                    아이디나 비밀번호 잘못 기입했을 시 뜨는 문구입니다.
                    -->
                    <p class="login_fail" v-if="errorMsg">{{errorMsg}}</p>
                    <!-- e: 경고문구 -->
                    <!-- s: 로그인 버튼 -->
                    <div class="btn_area login">
                        <button type="button" :class="`btn full strong h_l ${!canLogin() ? 'disabled' : ''}`"
                                @click="login(email, password)"
                                :disabled="!canLogin()">로그인
                        </button>
                    </div>
                    <!-- e: 로그인 버튼 -->

                    <div class="info_box_wrap">
                        <p>© 2021. KT all rights reserved.</p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "login",
    layout: {
        name: 'login',
    },
    data() {
        return {
            errorMsg: '',
            email: '',
            password: '',
        }
    },
    methods: {
        idValidation(){
            const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
            return emailRule.test(this.email);

        },
        pwValidation(){
            return this.password;
        },
        canLogin() {
            return this.idValidation() && this.pwValidation();
        },
        login(email, password) {
            if (!this.canLogin()) {
                return;
            }
            this.$store
                    .dispatch("LOGIN", {email, password})
                    .then((msg) => {
                        if(msg && msg.code){
                            switch (msg.code) {
                                case 'auth/user-not-found':
                                    this.errorMsg = '존재하지 않는 아이디입니다.';
                                    break;
                                case 'auth/wrong-password':
                                    this.errorMsg = '잘못된 비밀번호 입니다.'
                                    break;
                            }
                        }else{
                            this.errorMsg = '';
                            this.redirect();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
        },
        redirect() {
            this.$router.push('/')
        },
    }
}
</script>

<style scoped>

</style>